import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase";
import { FirestoreProvider } from "@react-firebase/firestore";
import "./index.css";
import { config } from "./firebase-config";
import { QuestionnaireForm } from "./questionnaire";

ReactDOM.render(
  <React.StrictMode>
    <div className="container py-4 border">
      <header className="p-4 bg-light">
        <div className="container">
          <div className="row">
            <div className="d-flex w-100 justify-content-between align-items-center">
              <div className="row w-100">
                <div className="col-12 col-lg-4 d-flex align-items-center">
                  {/*<h1 className="mb-0">Questionnaire</h1>*/}
                </div>

                <div className="col-12 mx-auto d-flex justify-content-end align-items-center">
                  <div className="row w-100">
                    <div className="col-lg-4 text-center">
                      <img
                        style={{ maxWidth: "12rem" }}
                        className="mr-lg-3"
                        src="/HFM-logo.png"
                      />
                    </div>
                    <div className="col-lg-4 text-center d-flex justify-center align-items-center">
                      <img className="mr-lg-3 d-block" src="/HMH.png" />
                    </div>
                    <div className="col-lg-4 text-center">
                      <img
                        // style={{ maxWidth: "17rem", height: "auto" }}
                        src="/WalkerCounty-logo.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="py-4">
        <div className="container">
          <div className="row">
            <div className="w-100 p-4 border">
              <QuestionnaireForm />
            </div>
          </div>
        </div>
      </main>
      <footer className="py-4">
        <div className="container">
          <div className="row">&copy; 2021</div>
        </div>
      </footer>
    </div>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
