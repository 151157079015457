import React, { useEffect } from "react";

function EventBriteEmbed() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.eventbrite.com/static/widgets/eb_widgets.js";
    script.async = true;

    document.body.appendChild(script);

    window.EBWidgets.createWidget({
      // Required
      widgetType: "checkout",
      eventId: "144300178719",
      iframeContainerId: "eventbrite-widget-container-144300178719",

      // Optional
      iframeContainerHeight: 825, // Widget height in pixels. Defaults to a minimum of 425px if not provided
    });


    window.EBWidgets.createWidget({
      // Required
      widgetType: "checkout",
      eventId: "144715807877",
      iframeContainerId: "eventbrite-widget-container-144715807877",

      // Optional
      iframeContainerHeight: 825, // Widget height in pixels. Defaults to a minimum of 425px if not provided
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="p-4 text-center">
      <div className="mt-4">
        <div className="row">
          <div
            id="eventbrite-widget-container-144300178719"
            style={{ width: "100%" }}
          />
        </div>
        <div className="row" style={{marginTop: '10rem'}}>
          <div
            id="eventbrite-widget-container-144715807877"
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
}

export { EventBriteEmbed };
